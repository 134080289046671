@tailwind base;
@tailwind components;
@tailwind utilities;

/* react-toastify */
.Toastify__progress-bar {
  &--bg,
  &.Toastify__progress-bar {
    &.Toastify__progress-bar--default {
      @apply bg-gradient-to-l from-bmw-blue-300 via-bmw-blue-500 to-bmw-red-500;
    }
  }
}

.input-border {
  @apply after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 after:translate-y-0.5 after:transform after:bg-gradient-to-r after:from-bmw-red-500 after:via-bmw-blue-500 after:to-bmw-blue-300 after:transition-all after:duration-300 after:content-[""] data-[focus=true]:after:w-full group-data-[invalid=true]:after:opacity-0;
}

::-webkit-scrollbar {
  @apply h-1 w-1 bg-neutral-500/20;
}

::-webkit-scrollbar-thumb {
  @apply rounded-full bg-neutral-500/50;
}
